// https://react-icons.github.io/react-icons/
import { BsKanbanFill } from "react-icons/bs";
import {
  FaCss3Alt,
  FaDocker,
  FaFlask,
  FaGitAlt,
  FaHtml5,
  FaJava,
  FaMicrosoft,
  FaPython,
  FaReact,
  FaVuejs
} from "react-icons/fa";
import {
  SiCplusplus,
  SiCypress,
  SiGatsby,
  SiGnubash,
  SiHeroku,
  SiJavascript,
  SiJest,
  SiKubernetes,
  SiLinux,
  SiPostgresql,
  SiSelenium,
  SiSpringboot
} from "react-icons/si";

// Projects Images
import castiron from "./assets/images/cast-iron.jpg";
import chezgusAPI from "./assets/images/Chez-Gus-logo-bluefade.png";
import chezgusUI from "./assets/images/Chez-Gus-logo-blocky.png";
import chickenbutt from "./assets/images/CB-logo.png";
import randomjoke from "./assets/images/random-joke-1.png";
import scrambler from "./assets/images/Scrambler.jpg";
import strat from "./assets/images/strat.jpg";

export const githubUsername = "apacific";
export const skillData = [
  {
    id: 1,
    skill: <SiGnubash className="display-4" />,
    name: "BASH"
  },
  {
    id: 2,
    skill: <FaCss3Alt className="display-4" />,
    name: "CSS",
  },
  {
    id: 3,
    skill: <SiCplusplus className="display-4" />,
    name: "C++",
  },
  {
    id: 4,
    skill: <FaMicrosoft className="display-4" />,
    name: "C# / .NET",
  },
  {
    id: 5,
    skill: <SiCypress className="display-4" />,
    name: "Cypress",
  },
  {
    id: 6,
    skill: <FaDocker className="display-4" />,
    name: "Docker"
  },
  {
    id: 7,
    skill: <FaFlask className="display-4" />,
    name: "Flask"
  },
  {
    id: 8,
    skill: <SiGatsby className="display-4" />,
    name: "Gatsby.js"
  },
  {
    id: 9,
    skill: <FaGitAlt className="display-4" />,
    name: "Git",
  },
  {
    id: 10,
    skill: <FaHtml5 className="display-4" />,
    name: "HTML",
  },
  {
    id: 11,
    skill: <SiHeroku className="display-4" />,
    name: "Heroku",
  },
  {
    id: 12,
    skill: <FaJava className="display-4" />,
    name: "Java",
  },
  {
    id: 13,
    skill: <SiJavascript className="display-4" />,
    name: "JavaScript",
  },
  {
    id: 14,
    skill: <SiJest className="display-4" />,
    name: "Jest",
  },
  {
    id: 15,
    skill: <BsKanbanFill className="display-4" />,
    name: "Kanban"
  },
  {
    id: 16,
    skill: <SiKubernetes className="display-4" />,
    name: "Kubernetes"
  },
  {
    id: 17,
    skill: <SiLinux className="display-4" />,
    name: "Linux"
  },
  {
    id: 18,
    skill: <SiPostgresql className="display-4" />,
    name: "PostgreSQL",
  },
  {
    id: 19,
    skill: <FaPython className="display-4" />,
    name: "Python",
  },
  {
    id: 20,
    skill: <FaReact className="display-4" />,
    name: "React",
  },
  {
    id: 21,
    skill: <SiSelenium className="display-4" />,
    name: "Selenium",
  },
  {
    id: 22,
    skill: <SiSpringboot className="display-4" />,
    name: "Spring Boot"
  },
  {
    id: 23,
    skill: <FaVuejs className="display-4" />,
    name: "Vue.js"
  },
];

/* Projects
 ************************************************************** */
// (will be sorted alphabetically); leaving the array empty will include everything
export const filteredProjects = [ "Cast-Iron-Fuzz", "ChezGus-REST-API", "ChezGus-UI", "Chicken-Butt-App", "Random-Joke-TPDNE", "Scrambler-Fuzz", "Strat-Partscaster-Guitar"];

// card images to include in the same order as the repos above (alphabetically)
// There must be one image per repo above, or the default image will be applied
export const projectData = [
    {
      image: castiron,
    },
    {
      image: chezgusAPI,
    },
    {
      image: chezgusUI,
    },
    {
      image: chickenbutt,
    },
    {
      image: randomjoke,
    },
    {
      image: scrambler,
    },
    {
      image: strat,
    },
];

export const contactInfo = {
  email: "tony.pacific@gmail.com",
  gitHub: "https://github.com/apacific",
  linkedIn: "https://www.linkedin.com/in/tony-pacific",
};
