// https://www.npmjs.com/package/react-scroll
import { Link } from "react-scroll";
// https://react-icons.github.io/react-icons/
import { FaChevronCircleDown } from "react-icons/fa";
import NavBar from "../containers/NavBar";

const About = ({ theme, setTheme, githubUrl, name, linkedInUrl, bio }) => {
  const newTheme = `${theme} d-flex flex-column min-vh-100 justify-content-center`;

  return (
    <header id="about" className={newTheme}>
      <NavBar theme={theme} setTheme={setTheme} />
      <div className="container text-center">
        <h1>{name}</h1>
        <hr />
        <h2>{bio.slice(0,-1).split(/\.\s/).map(str => <p><a href='https://github.com/apacific'>{str + "."}</a></p>)}</h2>
        <Link className="scroll" to="contact" smooth={true} duration={750}>
          <FaChevronCircleDown id="scroll-down" />
        </Link>
      </div>
    </header>
  );
};

export default About;
