import SocialLinks from "./SocialLinks";

const Footer = ({ githubUrl, linkedInUrl }) => {
  return (
    <footer className="d-flex flex-column justify-content-center align-items-center bg-dark">
      <SocialLinks githubUrl={githubUrl} linkedInUrl='https://www.linkedin.com/in/tony-pacific' />
      <p>
        &copy; 2023 Anthony Pacific
      </p>
    </footer>
  );
};

export default Footer;
