// https://react-icons.github.io/react-icons/
import { FaGithub, FaLinkedin } from "react-icons/fa";

const SocialLinks = ({ githubUrl, linkedInUrl }) => {

  return (
    <>
      <div className="social-links">
        <a href={githubUrl} target="_blank" rel="noreferrer">
          <FaGithub />
        </a>

        <a href={linkedInUrl} target="_blank" rel="noreferrer">
          <FaLinkedin />
        </a>
      </div>
    </>
  );
};

export default SocialLinks;
