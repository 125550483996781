// https://www.npmjs.com/package/react-scroll
import { Link } from "react-scroll";
// https://react-icons.github.io/react-icons/
import { FaChevronCircleUp } from "react-icons/fa";
import { contactInfo } from "../../data";

const mail = `mailto:${contactInfo.email}`;

const Contact = ({ theme, avatar, name }) => {
  const newTheme = `${theme} d-flex flex-column justify-content-center`;

  return (
    <section id="contact" className={newTheme}>
      <div className="container text-center">
        <h2>Contact</h2>
        <hr />
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img src={avatar} alt={name} />
          </div>
          <div className="col-lg-6">
            <div className="contact-info-container">
              <h3>{name}</h3>
              {contactInfo.email && contactInfo.email !== "" ? (
                <a className="email" href={mail}>
                  <span>{contactInfo.email}</span>
                </a>
              ) : (
                ""
              )}
              <br />
              {contactInfo.linkedIn && contactInfo.linkedIn !== "" ? (
                <a className="email" href={contactInfo.linkedIn}>
                  <span>{contactInfo.linkedIn}</span>
                </a>
              ) : (
                ""
              )}
              <br />
              {contactInfo.gitHub && contactInfo.gitHub !== "" ? (
                <a className="gitHub" href={contactInfo.gitHub}>
                  <span>{contactInfo.gitHub}</span>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Link className="scroll" to="about" smooth={true} duration={750}>
          <FaChevronCircleUp id="scroll-up" />
        </Link>
      </div>
    </section>
  );
};

export default Contact;
